<template>
  <v-row justify="end">
    <v-dialog
      v-model="isVisibleDialog"
      persistent
      max-width="600px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-if="$admin.can('users.create')"
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
          @click="!isVisibleDialog"
        >
          {{ $t("add_new") }}
        </v-btn>
      </template>

      <v-form
        v-if="isVisibleDialog"
        ref="form"
        lazy-validation
      >
        <v-card>
          <v-card-title>
            <span class="text-h3">{{ $t("add_new") }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-text-field
                    v-model="oem.oem_name"
                    dense
                    :label="$t('oem_name_*')"
                    :rules="[rules.required]"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-text-field
                    v-model="oem.oem_email"
                    dense
                    :label="$t('oem_email_*')"
                    :rules="[rules.required, rules.email]"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-text-field
                    v-model="oem.user_name"
                    dense
                    :label="$t('poc_name_*')"
                    :rules="[rules.required]"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-text-field
                    v-model="oem.user_email"
                    dense
                    :label="$t('poc_email_*')"
                    :rules="[rules.required, rules.email]"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-text-field
                    v-model="oem.contact_no"
                    dense
                    type="number"
                    :label="$t('contact_*')"
                    :rules="[
                      rules.required,
                      rules.min_length(8),
                      rules.max_length(11),
                    ]"
                  />
                </v-col>

                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-text-field
                    v-model="oem.address"
                    dense
                    :label="$t('address_*')"
                    :rules="[rules.required]"
                  />
                </v-col>

                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-file-input
                    v-model="oem.business_logo"
                    dense
                    accept="image/png, image/jpeg, image/bmp"
                    prepend-icon="mdi-camera"
                    :label="$t('business_logo_*')"
                    :rules="[rules.required, rules.file_size]"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-text-field
                    v-model="oem.no_of_users_account"
                    dense
                    type="number"
                    :min="3"
                    :label="$t('no_of_users_*')"
                    :rules="[rules.min_quantity(3)]"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <small>{{ $t("indicates_required_field") }}</small>
            <v-spacer />
            <v-btn
              color="red darken-1"
              text
              @click="closeForm()"
            >
              Close
            </v-btn>
            <v-btn
              color="primary"
              text
              :loading="isLoadingSave"
              @click="saveItem(oem)"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-row>
</template>

<script>
// import { mapGetters } from "vuex";
import { validationRules } from "@/mixins/validationRules";

export default {
  mixins: [validationRules],

  data() {
    return {
      isVisibleDialog: false,
      isLoadingSave: false,
      oem: {
        no_of_users_account: "3",
      },
    };
  },

  methods: {
    closeForm() {
      const usersAccount = this.oem.no_of_users_account;
      this.$refs.form.reset();
      this.oem.no_of_users_account = usersAccount;
      this.isVisibleDialog = false;
    },
    async saveItem(user) {
      if (this.$refs.form.validate()) {
        this.isLoadingSave = true;
        this.oem.name = this.oem.oem_name;
        this.oem.email = this.oem.user_email;
        await this.$store
          .dispatch("oemclients/add", user)
          .then(() => {
            this.$store.dispatch(
              "alerts/success",
              this.$t("created_successfully")
            );
            this.isLoadingSave = false;
            this.closeForm();
          })
          .catch((error) => {
            this.$store.dispatch("alerts/error", error.response?.data?.message);
            this.isLoadingSave = false;
          });
      }
    },
  },
};
</script>
